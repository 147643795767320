import { Component, Vue } from 'vue-property-decorator'
import {} from '@/components/base';
  
@Component({
  components: {}
})
export default class FeedSocialMedia extends Vue {
  get isMobileOrTablet(): boolean{
    return this.$store.getters['screen/isMobile'] || this.$store.getters['screen/isTablet'];
  }
}
  