import { Component, Vue } from 'vue-property-decorator';
// layouts
import layout from '@/layouts/basic/basic.vue';

import FeedSocialMedia from '@/components/feedSocialMedia/feedSocialMedia.vue';
import MainNews from '@/modules/news/components/mainNews/mainNews.vue';
import AnunciosBanners1 from '@/components/anunciosBanners1/anunciosBanners1.vue';
import LastNews from '@/modules/news/components/lastNews/lastNews.vue';
import NossaEquipe from '@/components/nossaEquipe/nossaEquipe.vue';

@Component({
  components: {
    layout,
    FeedSocialMedia,
    MainNews,
    AnunciosBanners1,
    LastNews,
    NossaEquipe
  },
})
export default class Home extends Vue {

  get isMobile(): boolean{
    return this.$store.getters['screen/isMobile'];
  }

}