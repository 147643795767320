import { Component, Vue } from 'vue-property-decorator'
import utils from '@/utils';
import news from '@/modules/news/services/news';

import {} from '@/components/base';
  
@Component({
  components: {}
})
export default class MainNews extends Vue {
  isLoading = false;
  list: any = [];

  ajustDate = utils.date.strDatetimeUSAToBR

  mounted() {
    this.getNewsEmDestaqye();
  }

  getNewsEmDestaqye(): void{
    this.isLoading = true;
    news.sendGetEmDestaque()
      .then( (response) => {
        this.list = response.data;
      })
      .finally( ()=>this.isLoading = false );
  }

  getPostRouteTo(post: any){
    return `/posts/${post.slug}`;
  }

  getPostThumbnail(post: any){
    return news.getThumbnail(post);
  }

}
  