import http from '@/services/api';
import { mountUrlWP } from '@/utils/mountURL'
import content from '@/services/content';


export function sendGetEmDestaque(): Promise<any> {
  // filter[categories]
  // ?_embed&per_page=3&page=1&order=desc&orderby=date&categories
  const url = mountUrlWP('/posts?_embed&per_page=3&page=1&order=desc&orderby=date&categories=12');
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function sendGetLastNews(): Promise<any> {
  const url = mountUrlWP('/posts?_embed&per_page=4&page=1&order=desc&orderby=date');
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function sendGetCarouselList(): Promise<any> {
  const url = mountUrlWP('/get_posts/?destaque=slide&count=4');
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function sendGetAllNews(query = ''): Promise<any> {
  let url = mountUrlWP('/get_posts/?slug=noticia&orderby=date&order=desc&post_type=post');
  url = (query != '') ? url +'&'+ query : url;
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function getThumbnail(post: any) {
  if(post._embedded['wp:featuredmedia'] != undefined){
    return post._embedded['wp:featuredmedia'][0].source_url;
  }
  const images = require.context('@/assets/img/', false, /\.jpg$/)
  return images('./' + 'sem_imagem' + ".jpg");
}

export default {
  sendGetEmDestaque,
  sendGetCarouselList,
  sendGetAllNews,
  getThumbnail,
  sendGetLastNews
}