import { Component, Vue } from 'vue-property-decorator'
import content from '@/services/content';
import {} from '@/components/base';
  
@Component({
  components: {}
})
export default class AnunciosBanners1 extends Vue {

  anuncios = [];

  get isMobile(): boolean{
    return this.$store.getters['screen/isMobile'];
  }

  mounted() {
    this.sendGetAnuncios();
  }

  sendGetAnuncios(){
    content.sendGetAnuncio()
      .then( (response) => {
        this.anuncios = response.data;
        // console.log(this.list[0]._embedded['wp:featuredmedia'][0].source_url);
        // console.log(this.list[0]._embedded['wp:featuredmedia']);
        
      })
  }

  getUrlImage(post: any){
    return content.getThumbnail(post);
  }

  getUrlImageMobile(post: any){
    return content.getImageMobile(post);
  }

}
  