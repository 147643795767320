

export function jsonToURLQuery(objQuery: any): string {
  let str  = '';
  let i = 1;
  for(const key in objQuery){
    str = str + key + '=' + objQuery[key];
    if( i >= 1 && i < Object.keys(objQuery).length){
      str = str + '&'
    }
    i = i + 1;
  }
  return str;
}

export default {
  jsonToURLQuery
}