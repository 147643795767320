import { Component, Vue } from 'vue-property-decorator'
import http from '@/services/api';
import utils from '@/utils';
import content from '@/services/content';
import {} from '@/components/base';
  
@Component({
  components: {}
})
export default class NossaEquipe extends Vue {
  isLoading = false;
  list: any = [];

  mounted() {
    this.sendGetAnuncio();
  }

  getImage(post: any){
    return content.getThumbnail(post);
  }

  sendGetAnuncio() {
    const url = utils.mountURL.mountUrlWP('/equipe?_embed');
    http.get(url)
      .then( (response) => {
        this.list = response.data;
      })
      .catch( (error) => {
        console.log(error);
        this.list = [];
      });
  }

  getTitle(item: any){
    return item.title.rendered.split('/')[0];
  }

  getCargo(item: any){
    if(item.title.rendered.split('/').length > 1){
      return item.title.rendered.split('/')[1];
    }
    return '';
  }

}
  