
// string datetime 2021-10-15 => 15/10/2021
export function strDatetimeUSAToBR(stringDate: string): any{
	if( stringDate == undefined ){
		return ''
	}
	stringDate = stringDate.split('T')[0];
	stringDate = stringDate.split(' ')[0];
	const aux = stringDate.split('-');
	return `${aux[2]}/${aux[1]}/${aux[0]}`;
}

// ajusta exibição da hora 08:30:55 to 08:30
export function ajustHour(hora: any) {
	if( hora == undefined ){
		return ''
	}
	hora = hora.split(':');
	hora = `${hora[0]}:${hora[1]}`
	return hora;
}

export default{
  strDatetimeUSAToBR,
	ajustHour
}