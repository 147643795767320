// Verifica se string esta vazia
export function isEmpty(value: string | null): boolean {
	if (value) {
		return (value.length === 0 || !value.trim());
	} return true
}


export default{
  isEmpty,
}