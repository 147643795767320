import { Component, Vue } from 'vue-property-decorator';
import news from '@/modules/news/services/news';
import {} from '@/components/base';
  
@Component({
  components: {}
})
export default class LastNews extends Vue {
  isLoading = false;
  list: any = [];

  mounted() {
    this.getLastNews();
  }

  getLastNews(): void{
    this.isLoading = true;
    news.sendGetLastNews()
      .then( (response) => {
        this.list = response.data;
        
      })
      .finally( ()=>this.isLoading = false );
  }

}
  