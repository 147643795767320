import string from './string'
import date from './date'
import mountURL from './mountURL'
import queryURL from './queryURL'

export default{
  string,
  date,
  mountURL,
  queryURL,
}